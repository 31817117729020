import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import ZeydianLogo from '../zeydian-log.PNG';
import MenuItem from "@mui/material/MenuItem";

const logoStyle = {
    width: '140px',
    height: 'auto',
};

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" mt={1}>
            {'Copyright © '}
            <Link href="https://zeydian.com/">Zeydian Technologies Limited&nbsp;</Link>
            {new Date().getFullYear()}
        </Typography>
    );
}

export default function Footer() {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        const offset = 128;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({behavior: 'smooth'});
            window.scrollTo({
                top: targetScroll,
                behavior: 'smooth',
            });
            setOpen(false);
        }
    };

    return (
        <Container
            id="footer"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: {xs: 4, sm: 8},
                py: {xs: 4, sm: 8},
                textAlign: {sm: 'center', md: 'left'},
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', sm: 'row'},
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        minWidth: {xs: '100%', sm: '60%'},
                    }}
                >
                    <Box sx={{width: {xs: '100%', sm: '60%'}}}>
                        <Box sx={{ml: '-15px'}}>
                            <img
                                src={
                                    ZeydianLogo
                                }
                                style={logoStyle}
                                alt="logo of Zeydian Technologies"
                            />
                            <Typography variant="body2" gutterBottom sx={{fontWeight: 600, mt: 2}}>
                                Contact
                            </Typography>
                            <Typography variant="body2"
                                        sx={{color: 'text.secondary', mb: 2, display: 'flex', alignItems: 'center'}}>
                                <PhoneIcon sx={{mr: 1}}/>
                                <a href="tel:+260956173900" style={{color: 'inherit', textDecoration: 'none'}}>+260
                                    (956) 173-900</a>
                            </Typography>
                            <Typography variant="body2"
                                        sx={{color: 'text.secondary', mb: 2, display: 'flex', alignItems: 'center'}}>
                                <EmailIcon sx={{mr: 1}}/>
                                <a href="mailto:info@zeydian.com"
                                   style={{color: 'inherit', textDecoration: 'none'}}>info@zeydian.com</a>
                            </Typography>
                            <Typography variant="body2"
                                        sx={{color: 'text.secondary', mb: 2, display: 'flex', alignItems: 'center'}}>
                                <LocationOnIcon sx={{mr: 1}}/>
                                Lusaka, ZM
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: {xs: 'none', sm: 'flex'},
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        Products and services
                    </Typography>
                    <MenuItem
                        onClick={() => scrollToSection('hero')}
                        sx={{py: '6px', px: '12px'}}
                    >
                        <Typography variant="body2" color="text.primary">
                            Tandizo
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        sx={{py: '6px', px: '12px'}}
                    >
                        <Link variant="body2" color="text.primary"
                              href="https://data.zeydian.com/api/swagger-ui/index.html" target="_blank">
                            Zeydian Data API (Open source)
                        </Link>
                    </MenuItem>
                    <MenuItem
                        onClick={() => scrollToSection('footer')}
                        sx={{py: '6px', px: '12px'}}
                    >
                        <Typography variant="body2" color="text.primary">
                            Software Development and Consultancy
                        </Typography>
                    </MenuItem>
                </Box>
                <Box
                    sx={{
                        display: {xs: 'none', sm: 'flex'},
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        Company
                    </Typography>
                    <MenuItem
                        onClick={() => scrollToSection('about')}
                        sx={{py: '6px', px: '12px'}}
                    >
                        <Typography variant="body2" color="text.primary">
                            About Us
                        </Typography>
                    </MenuItem>
                </Box>
                <Box
                    sx={{
                        display: {xs: 'none', sm: 'flex'},
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        Legal
                    </Typography>
                    <MenuItem
                        onClick={() => scrollToSection('#')}
                        sx={{py: '6px', px: '12px'}}
                    >
                        <Typography variant="body2" color="text.primary">
                            Terms
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => scrollToSection('#')}
                        sx={{py: '6px', px: '12px'}}
                    >
                        <Typography variant="body2" color="text.primary">
                            Privacy
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => scrollToSection('footer')}
                        sx={{py: '6px', px: '12px'}}
                    >
                        <Typography variant="body2" color="text.primary">
                            Contact
                        </Typography>
                    </MenuItem>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pt: {xs: 4, sm: 8},
                    width: '100%',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <div>
                    <Link color="text.secondary" href="#">
                        Privacy Policy
                    </Link>
                    <Typography display="inline" sx={{mx: 0.5, opacity: 0.5}}>
                        &nbsp;•&nbsp;
                    </Typography>
                    <Link color="text.secondary" href="#">
                        Terms of Service
                    </Link>
                    <Copyright/>
                </div>
                <Stack
                    direction="row"
                    justifyContent="left"
                    spacing={1}
                    useFlexGap
                    sx={{
                        color: 'text.secondary',
                    }}
                >
                    <IconButton
                        color="inherit"
                        href="https://github.com/zeydian/"
                        aria-label="GitHub"
                        sx={{alignSelf: 'center'}}
                    >
                        <FacebookIcon/>
                    </IconButton>
                    <IconButton
                        color="inherit"
                        href="https://x.com/ZeydianTech/"
                        aria-label="X"
                        sx={{alignSelf: 'center'}}
                    >
                        <TwitterIcon/>
                    </IconButton>
                    <IconButton
                        color="inherit"
                        href="https://www.linkedin.com/company/zeydian/"
                        aria-label="LinkedIn"
                        sx={{alignSelf: 'center'}}
                    >
                        <LinkedInIcon/>
                    </IconButton>
                </Stack>
            </Box>
        </Container>
    );
}
